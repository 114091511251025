<template>
  <div class="modal fade" v-bind:class="{'show': report}" id="reportModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document" v-if="report">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{i18n('reports.title')}}</h5>
          <button type="button" class="close" v-on:click="dismiss">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" v-if="!report.id">
            <label for="reportDescription">{{i18n('reports.description')}}</label>
            <textarea class="form-control" id="reportDescription" rows="3" v-model="report.description"></textarea>
          </div>
          <div v-else class="alert alert-info">
            {{i18n('reports.already_reported')}}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="dismiss">{{i18n('reports.cancel')}}</button>
          <button type="button" class="btn btn-primary" v-if="!report.id" v-on:click.prevent="sendReport">{{i18n('reports.send')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import i18nmixin from '../customs/i18nmixin'
import $ from "jquery";
import 'bootstrap/js/dist/modal';

export default {
  name: 'report',
  mixins: [i18nmixin],
  mounted: function() {
  },
  data: function() {
    return {
      report: null
    }
  },
  methods: {
    fetchReport(type, id) {
      var self = this;
      window.axios.get(window.langtag_path+'/api/reports.json', {
        params: {
          type: type,
          id: id
        }
      })
      .then(function(response) {
        self.report = response.data;
        // $('#reportModal').modal('show');
      }).catch(function (error) {
        if(!error.response) {
            console.log(error);
        }
        if(error.response.status == 401) {
          if(error.response.data && error.response.data.error) {
            toastr.error(error.response.data.error);

          }
          $('#loginModal').modal('show');
          $('.login-tabs, .login-modal__nav a').removeClass('active');
          $('.loginModalTab, .loginModal').addClass('active');
        } else {
          // handle error
        }
      });
    },
    dismiss() {
      this.report = null;
    },
    sendReport() {
      var self = this;
      window.axios.post(window.langtag_path+'/api/reports.json', {
        type: this.report.reportable_type.toLowerCase(),
        id: this.report.reportable_id,
        description: this.report.description
      }).then(function(response) {
        toastr.success(response.data.success);
        // $('#reportModal').modal('hide');
        self.report = null;
      }).catch(function (error) {
        if(!error.response) {
            console.log(error);
        }
        if(error.response.status == 401) {
          if(error.response.data && error.response.data.error) {
            toastr.error(error.response.data.error);

          }
          $('#loginModal').modal('show');
          $('.login-tabs, .login-modal__nav a').removeClass('active');
          $('.loginModalTab, .loginModal').addClass('active');
        } else {
          // handle error
        }
      });
    }
  }
}
</script>
