import Vue from 'vue'
import Report from '../components/report.vue'
import underscore from 'vue-underscore'
Vue.use(underscore)
document.addEventListener('DOMContentLoaded', () => {
  if(document.getElementById('report')) {
    window.report = new Vue({
       el: '#report',
      render: h => h(Report)
    });
  }
});
